import { Calendar } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import Handlebars from "handlebars";

dayjs.extend(customParseFormat);

export function initialiseCalendars() {
  let tmsCalendarEl = document.getElementById('calendar-tms');
  if (tmsCalendarEl) {
    let tmsCalendar = new Calendar(tmsCalendarEl, {
      schedulerLicenseKey: '0975927247-fcs-1711273700',
      plugins: [resourceTimelinePlugin, interactionPlugin],
      initialView: 'resourceTimeline',
      firstDay: 1,
      resourceAreaWidth: '10%',
      resources: '/portal/feeds/tables-feed',
      editable: true,
      eventStartEditable: true,
      eventDurationEditable: true,
      eventResourceEditable: true,
      events: '/portal/feeds/bookings-feed',
      slotMinTime: '09:00:00',
      slotMaxTime: '22:00:00',
      slotDuration: '00:15:00',
      slotLabelInterval: '00:15:00',
      nowIndicator: true,

    eventContent: (info) => {
      const { title, extendedProps } = info.event;
      const { membershipStatus } = extendedProps;
      const { lineItemsSummary } = extendedProps;
      const { orderCount } = extendedProps;
      const { loyaltyPoints } = extendedProps;
    
      const priorityClass = membershipStatus === 'member' ? 'member' : 'non-member';
    
      // Add the membership status if it exists
      // const membershipStatusHtml = membershipStatus
      // ? `<span class="membership-status">${membershipStatus}</span>`
      // : '';

      return {
        html: `
          <div class="tms-cal-event py-1 px-2 ${priorityClass}">
            <div class="flex items-center justify-between">
              <span class="font-bold leading-none">${title}</span>
              <span class="leading-none font-bold">🚶🏽‍♀️${orderCount}</span>
            </div>
            <div class="flex items-center justify-between">
              <span class="font-bold leading-none">⭐️ ${loyaltyPoints}</span>
              <span class="leading-none font-bold">${lineItemsSummary}</span>
            </div>
          </div>
        `
      };
    },

    // Event dragging handler
    eventDrop: function(info) {
      const event = info.event;
      const newStart = dayjs(event.start).format('YYYY-MM-DD HH:mm');
      const newEnd = dayjs(event.end).format('YYYY-MM-DD HH:mm');
      const newTableId = info.newResource?.id || event.getResources()[0]?.id;

      // Confirmation alert
      if (confirm(`Are you sure you want to move this event to start at ${newStart} and end at ${newEnd}?`)) {
          fetch(`hlpc/booking/update-order`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            },
            body: JSON.stringify({
              orderId: event.extendedProps.orderId,
              tableAssignedId: newTableId,
              slotStart: newStart,
              slotEnd: newEnd,
            }),
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to update the order');
            }
          })
          .then(() => {
            setTimeout(() => {
              tmsCalendar.refetchEvents();
            }, 500);
          })
          .catch((error) => {
            console.error('Error updating order:', error);
            alert('An error occurred while updating the order.');
            info.revert();
          });
      } else {
        // Revert the drop if canceled
        info.revert();
      }
    },

    // Event resize hanler
    eventResize: function(info) {
      const event = info.event;
      const newStart = dayjs(event.start).format('YYYY-MM-DD HH:mm');
      const newEnd = dayjs(event.end).format('YYYY-MM-DD HH:mm');
      const tableId = event.getResources()[0]?.id;

      if (!tableId) {
          console.error('No valid table ID found for event resize.');
          info.revert();
          return;
      }

      if (confirm(`Are you sure you want to change the time of this event to start at ${newStart} and end at ${newEnd}?`)) {
          fetch(`/hlpc/booking/update-order`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
              },
              body: JSON.stringify({
                  orderId: event.extendedProps.orderId,
                  tableAssignedId: tableId,
                  slotStart: newStart,
                  slotEnd: newEnd,
              }),
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to update the order');
            }
          })
          .then(() => {
            setTimeout(() => {
              tmsCalendar.refetchEvents();
            }, 500);
          })
          .catch((error) => {
              console.error('Error updating order:', error);
              alert('An error occurred while updating the order.');
              info.revert();
          });
      } else {
          info.revert();
      }
    },

    eventClick: function(info) {
      console.log("Clicked event order ID:", info.event.extendedProps.orderId);  // This should log different orderIds for different events.
      fetchOrderInfo(info.event.extendedProps.orderId)
          .then(orderInfo => {
              populateModal(orderInfo);
              showModal('orderModal');
          })
          .catch(error => {
              console.error('Error fetching order information:', error);
          });
    },

    eventDidMount: function(info) {
        let endThreshold = new Date(info.event.end);
        endThreshold.setMinutes(endThreshold.getMinutes() - 15);

        let now = new Date();

        if (now >= endThreshold && now < info.event.end) {
          info.el.classList.add('flashing-event');
        }
      }
      
    });

      tmsCalendar.render();

    // Poll for new bookings and booking updates
    setInterval(function() {
      tmsCalendar.refetchEvents();
    }, 30000);
  }

  // Function to fetch the full order information
  function fetchOrderInfo(orderId) {
    console.log('Fetching order with ID:', orderId);  // Make sure this logs different IDs.
    return fetch(`/portal/order?id=${orderId}`)
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to fetch order information');
        }
        return response.json();
    });
  }


  // Function to populate the modal window with order information
  function populateModal(orderInfo) {
    console.log(orderInfo);

    const orderIdElement = document.querySelector('#orderId span');
    const customerNameElement = document.querySelector('#customerName span');
    const customerPhoneElement = document.querySelector('#customerPhone span');
    const itemsListElement = document.querySelector('#itemsList');
    const form = document.querySelector('#orderDetailsContent form');

    if (orderIdElement) {
        orderIdElement.textContent = orderInfo.orderId || 'N/A';
    }

    if (customerNameElement) {
        customerNameElement.textContent = orderInfo.fullName || 'No Name Available';
    }

    if (customerPhoneElement) {
        customerPhoneElement.textContent = orderInfo.phone || 'No Phone Available';
    }

    if (itemsListElement) {
        itemsListElement.innerHTML = '';
        orderInfo.items.forEach(item => {
            const li = document.createElement('li');
            li.textContent = `${item.description} - Quantity: ${item.quantity}`;
            itemsListElement.appendChild(li);
        });
    }

    if (form) {
      form.querySelector('input[name="orderId"]').value = orderInfo.orderId;
  }


  if (itemsListElement) {
      itemsListElement.innerHTML = ''; // Clear any previous entries
      orderInfo.items.forEach(item => {
          const li = document.createElement('li');
          li.textContent = `${item.description} - Quantity: ${item.quantity}`;
          itemsListElement.appendChild(li);
      });
  } else {
      console.error('Items list element not found.');
  }
}

  function showModal(modalId) {
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.style.display = 'block';
    } else {
      console.error('Modal element not found.');
    }
  }

  let selectableCalendarEl = document.getElementById('calendar-select');
  if (selectableCalendarEl) {

    const availabilityDataJson = selectableCalendarEl.getAttribute('data-availability');
    const availabilityData = JSON.parse(availabilityDataJson);

    const availabilityKeys = Object.keys(availabilityData);

    const validRangeStart = availabilityKeys.length > 0 ? dayjs(availabilityKeys[0], 'DD-MM-YYYY').format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    const validRangeEnd = availabilityKeys.length > 0 ? dayjs(availabilityKeys[availabilityKeys.length - 1], 'DD-MM-YYYY').format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');

    const source = document.getElementById("availability-slot-template").innerHTML;
    const slotAvailabilityTemplate = Handlebars.compile(source); 

    let selectableCalendar = new Calendar(selectableCalendarEl, {
      schedulerLicenseKey: '0975927247-fcs-1711273700',
      plugins: [interactionPlugin, dayGridPlugin],
      initialView: 'dayGridMonth',
      firstDay: 1,
      selectable: true,
      initialDate: null,
      dayCellClassNames: (info) => {

        const formattedDate = dayjs(info.date).format('DD-MM-YYYY');

        if (availabilityKeys.includes(formattedDate)) {
          return ['has-slots'];
        }
        else {
          return ['no-slots'];
        }

      },
      visibleRange: {
        start: validRangeStart,
        end: validRangeEnd
      }, 
      selectAllow: (info) => {

        const formattedDate = dayjs(info.start).format('DD-MM-YYYY');

        if (availabilityKeys.includes(formattedDate)) {
          return true;
        }
        else {
          return false;
        }

      },
      dateClick: (info) => {

        const formattedDate = dayjs(info.date).format('DD-MM-YYYY');

        if (!availabilityKeys.includes(formattedDate)) return;

        var clickedDate = new Date(info.dateStr + 'T00:00');

        var calendarAvailabilityDiv = document.querySelector('.calendar-slots');
        calendarAvailabilityDiv.classList.add('show-slots');

        var calendarDateSelected = document.querySelector('.calendar-date-selected');

        calendarDateSelected.textContent = clickedDate.toLocaleDateString('en-GB', {
          weekday: 'long',
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        }).replace(/,/g, '');

        const selectedDate = document.getElementById('selectedDate');
        const selectedTime = document.getElementById('selectedTime');
        const form = document.querySelector('#dateSelectionForm');

        selectedDate.value = info.dateStr;
        selectedDate.dataset.date = info.dateStr;

        // Build the template for slots.
        const dateAvailability = availabilityData[formattedDate];
        const slots = Object.keys(dateAvailability);
        const availabilityHtml = slotAvailabilityTemplate({slots: slots});

        document.getElementById('slot-availability').innerHTML = availabilityHtml;
        
        // Re-bind the radio buttons                
        document.querySelectorAll('input[type="radio"].time-slot-button').forEach(radio => {
            radio.addEventListener('change', function() {
                selectedTime.value = this.value;

                if (selectedDate.value && selectedTime.value) {
                    form.submit();
                }
            });
        });        
      }
    });
    selectableCalendar.render();
  }
}